<template>
  <div>
    <v-dialog v-model="visible" width="900">
      <v-card>
        <v-card-title class="text-h5"> Adjust Metadata Filter </v-card-title>

        <v-card-text>
          <div class="flex items-center justify-end mb-2">
            <v-btn color="primary" text @click="insertFilter">
              Add Filter
            </v-btn>
          </div>
          <div v-if="!filterData.length" class="mb-2">
            <div
              class="rounded bg-red-50 text-red-500 font-semibold p-5 text-[11px] mb-2"
            >
              There is no any filter. You need to create a filter.
            </div>
          </div>

          <div v-else>
            <div v-for="(filter, index) in filterData" :key="index">
              <div class="grid grid-cols-12 gap-2.5">
                <div class="col-span-4 flex flex-col">
                  <div class="text-[10px] font-semibold flex items-center">
                    1. Target
                    <span
                      class="text-red-700 text-[8px] pl-1 hover:underline cursor-pointer"
                      @click="deleteFilter(index)"
                      >Delete</span
                    >
                  </div>
                  <div>
                    <v-autocomplete
                      hide-details
                      class="text-[13px]"
                      outlined
                      clearable
                      dense
                      :items="
                        computedStandartTags.filter(
                          (x) => x.inputType !== 'color'
                        )
                      "
                      v-model="filter.target"
                      item-text="key"
                      return-object
                      hide-no-data
                    >
                    </v-autocomplete>
                  </div>
                </div>
                <div class="col-span-4 flex flex-col">
                  <div class="text-[10px] font-semibold">2. Condition</div>
                  <div>
                    <v-autocomplete
                      hide-details
                      class="text-[13px]"
                      outlined
                      clearable
                      dense
                      :items="
                        computedConditions(
                          filter.target ? filter.target.inputType : null
                        )
                      "
                      v-model="filter.condition"
                      hide-no-data
                      :disabled="!filter.target"
                      item-text="title"
                      item-value="title"
                    >
                    </v-autocomplete>
                  </div>
                </div>
                <div class="col-span-4 flex flex-col">
                  <div class="text-[10px] font-semibold">3. Value</div>
                  <div v-if="filter.target">
                    <v-text-field
                      v-if="
                        ['string', 'number'].includes(filter.target.inputType)
                      "
                      outlined
                      v-model="filter.value"
                      dense
                      hide-details
                      class="text-[13px]"
                      :type="
                        filter.target.inputType === 'string' ? 'text' : 'number'
                      "
                    ></v-text-field>

                    <v-autocomplete
                      v-else-if="
                        ['string[]', 'number[]'].includes(
                          filter.target.inputType
                        )
                      "
                      hide-details
                      class="text-[13px]"
                      outlined
                      clearable
                      dense
                      :items="computedItems(filter.target)"
                      v-model="filter.value"
                      hide-no-data
                      multiple
                    >
                    </v-autocomplete>

                    <v-autocomplete
                      v-else-if="['boolean'].includes(filter.target.inputType)"
                      hide-details
                      class="text-[13px]"
                      outlined
                      clearable
                      dense
                      :items="['true', 'false']"
                      v-model="filter.value"
                      hide-no-data
                    >
                    </v-autocomplete>
                  </div>
                  <div v-else>
                    <v-text-field
                      outlined
                      v-model="filter.value"
                      dense
                      hide-details
                      class="text-[13px]"
                      disabled
                    ></v-text-field>
                  </div>
                </div>
              </div>
              <div
                class="mt-3 font-semibold text-gray-500 text-[10px] text-center"
                v-if="index !== filterData.length - 1"
              >
                AND
              </div>
            </div>
          </div>
        </v-card-text>

        <v-card-actions>
          <div class="flex items-center justify-between">
            <v-btn color="red darken-1" text @click="visible = false">
              Cancel
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="submitFilterData"
              :disabled="isSubmitBtnDisabled"
            >
              Submit
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ACT_STANDART_TAGS } from "@/helper/utils";

export default {
  data() {
    return {
      visible: false,
      filterData: [],
      conditions: [
        {
          title: "Contains",
          for: ["string"],
        },
        {
          title: "Includes",
          for: ["string[]", "number[]"],
        },
        {
          title: "Does not include",
          for: ["string[]", "number[]"],
        },
        {
          title: "Exactly matches",
          for: ["string", "number", "boolean"],
        },
        {
          title: "Does not exactly match",
          for: ["string", "number", "boolean"],
        },
        {
          title: "Does not contain",
          for: ["string"],
        },
        {
          title: "Greater than",
          for: ["number", "number[]"],
        },
        {
          title: "Lower than",
          for: ["number", "number[]"],
        },
      ],
    };
  },
  props: {
    tags: {
      type: Array,
      default: () => [],
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    show: {
      handler(newValue) {
        this.visible = newValue;
      },
      inmediate: true,
    },
    visible(newValue) {
      if (!newValue) {
        this.$emit("closed");
        this.result = null;
        this.test_type = "first";
      }
    },
  },
  methods: {
    deleteFilter(index) {
      this.filterData.splice(index, 1);
    },
    submitFilterData() {
      this.$emit("submit", this.filterData);
    },
    computedItems(target_type) {
      const tags = JSON.parse(JSON.stringify(this.tags));

      for (let i = 0; i < tags.length; i++) {
        const tag = tags[i];
        if (tag.name === target_type.key) {
          return JSON.parse(tag.info);
        }
      }

      return [];
    },
    computedConditions(target_type) {
      return this.conditions.filter((x) => x.for.includes(target_type));
    },
    insertFilter() {
      const initializedFilter = {
        condition: null,
        target: null,
        value: null,
      };

      this.filterData.push(initializedFilter);
    },
    errorHandler(error) {
      if ("response" in error) {
        if ("data" in error["response"]) {
          const isArr = this.$isArr(error["response"]["data"]["detail"]);
          var errorMessage = error["response"]["data"]["detail"];
          if (isArr) {
            errorMessage = JSON.stringify(errorMessage);
          }
          this.$toast.error(errorMessage, {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });
        }
        return;
      } else if ("message" in error) {
        this.$toast.error(error["message"], {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      } else {
        this.$toast.error(error.message, {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      }
    },
  },
  computed: {
    computedStandartTags() {
      var tags = JSON.parse(JSON.stringify(ACT_STANDART_TAGS));

      tags.push({
        availableValues: null,
        inputType: "number",
        label: "Duration MS",
        multipleAllowed: false,
        key: "duration_ms",
      });
      return tags;
    },

    isSubmitBtnDisabled() {
      if (!this.filterData.length) return false;
      for (let i = 0; i < this.filterData.length; i++) {
        const filterData = this.filterData[i];
        if (!filterData.condition || !filterData.value || !filterData.target)
          return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
