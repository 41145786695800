<template>
  <div>
    <v-dialog v-model="visible" width="600">
      <v-card>
        <v-card-title> Save Recorded Gameplay </v-card-title>

        <v-card-text>
          <div class="mt-5">
            <v-text-field
              label="Gameplay Name"
              outlined
              v-model="gameplay_title"
              dense
              hide-details
              class="text-[13px]"
            ></v-text-field>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="saveGameplay"
            :disabled="isBtnDisabled"
            :loading="loading"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    blob_info: {
      type: String,
      default: null,
    },
    used_parameters: {
      type: Object,
      default: () => {
        return {};
      },
    },
    levelData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    asset_id: {
      type: Number,
      default: null,
    },
    build_id: {
      type: Number,
      default: null,
    },
  },
  watch: {
    show(newValue) {
      this.visible = newValue;
    },
    visible(newValue) {
      if (!newValue) {
        this.$emit("closed");
      }
    },
  },
  data() {
    return {
      visible: false,
      gameplay_title: "",
      loading: false,
    };
  },
  methods: {
    async saveGameplay() {
      try {
        this.loading = true;
        let requestData = {
          title: this.gameplay_title,
          modified_title: this.$makeModifiedTitle(this.gameplay_title),
          blob_info: this.blob_info,
          used_parameters: JSON.stringify(this.used_parameters),
          asset_id: this.asset_id,
          build_id: this.build_id,
          video_title: this.blob_info.split("/").pop(),
        };

        const response = await this.$api.post(
          "acg/base-creatives/create",
          requestData
        );

        if (response.status === 200) {
          this.gameplay_title = "";
          this.$toast.success(
            "Gameplay has successfuly saved! You can add asset from 'Import AE Project' page.",
            {
              timeout: 5000,
              position: "bottom-center",
              icon: false,
            }
          );
          this.visible = false;
        }
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.loading = false;
      }
    },

    errorHandler(error) {
      if ("response" in error) {
        if ("data" in error["response"]) {
          const isArr = this.$isArr(error["response"]["data"]["detail"]);
          var errorMessage = error["response"]["data"]["detail"];
          if (isArr) {
            errorMessage = JSON.stringify(errorMessage);
          }

          this.$toast.error(errorMessage, {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });
        }
        return;
      } else if ("message" in error) {
        this.$toast.error(error["message"], {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });

        return;
      } else {
        this.$toast.error(error.message, {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      }
    },
  },
  computed: {
    isBtnDisabled() {
      if (!this.gameplay_title) return true;
      if (!this.blob_info) return true;
      if (!this.used_parameters) return true;
      if (!this.asset_id) return true;
      if (!this.build_id) return true;
      return false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
