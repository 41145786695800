var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-dialog',{attrs:{"width":"900"},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Adjust Metadata Filter ")]),_c('v-card-text',[_c('div',{staticClass:"flex items-center justify-end mb-2"},[_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.insertFilter}},[_vm._v(" Add Filter ")])],1),(!_vm.filterData.length)?_c('div',{staticClass:"mb-2"},[_c('div',{staticClass:"rounded bg-red-50 text-red-500 font-semibold p-5 text-[11px] mb-2"},[_vm._v(" There is no any filter. You need to create a filter. ")])]):_c('div',_vm._l((_vm.filterData),function(filter,index){return _c('div',{key:index},[_c('div',{staticClass:"grid grid-cols-12 gap-2.5"},[_c('div',{staticClass:"col-span-4 flex flex-col"},[_c('div',{staticClass:"text-[10px] font-semibold flex items-center"},[_vm._v(" 1. Target "),_c('span',{staticClass:"text-red-700 text-[8px] pl-1 hover:underline cursor-pointer",on:{"click":function($event){return _vm.deleteFilter(index)}}},[_vm._v("Delete")])]),_c('div',[_c('v-autocomplete',{staticClass:"text-[13px]",attrs:{"hide-details":"","outlined":"","clearable":"","dense":"","items":_vm.computedStandartTags.filter(
                        (x) => x.inputType !== 'color'
                      ),"item-text":"key","return-object":"","hide-no-data":""},model:{value:(filter.target),callback:function ($$v) {_vm.$set(filter, "target", $$v)},expression:"filter.target"}})],1)]),_c('div',{staticClass:"col-span-4 flex flex-col"},[_c('div',{staticClass:"text-[10px] font-semibold"},[_vm._v("2. Condition")]),_c('div',[_c('v-autocomplete',{staticClass:"text-[13px]",attrs:{"hide-details":"","outlined":"","clearable":"","dense":"","items":_vm.computedConditions(
                        filter.target ? filter.target.inputType : null
                      ),"hide-no-data":"","disabled":!filter.target,"item-text":"title","item-value":"title"},model:{value:(filter.condition),callback:function ($$v) {_vm.$set(filter, "condition", $$v)},expression:"filter.condition"}})],1)]),_c('div',{staticClass:"col-span-4 flex flex-col"},[_c('div',{staticClass:"text-[10px] font-semibold"},[_vm._v("3. Value")]),(filter.target)?_c('div',[(
                      ['string', 'number'].includes(filter.target.inputType)
                    )?_c('v-text-field',{staticClass:"text-[13px]",attrs:{"outlined":"","dense":"","hide-details":"","type":filter.target.inputType === 'string' ? 'text' : 'number'},model:{value:(filter.value),callback:function ($$v) {_vm.$set(filter, "value", $$v)},expression:"filter.value"}}):(
                      ['string[]', 'number[]'].includes(
                        filter.target.inputType
                      )
                    )?_c('v-autocomplete',{staticClass:"text-[13px]",attrs:{"hide-details":"","outlined":"","clearable":"","dense":"","items":_vm.computedItems(filter.target),"hide-no-data":"","multiple":""},model:{value:(filter.value),callback:function ($$v) {_vm.$set(filter, "value", $$v)},expression:"filter.value"}}):(['boolean'].includes(filter.target.inputType))?_c('v-autocomplete',{staticClass:"text-[13px]",attrs:{"hide-details":"","outlined":"","clearable":"","dense":"","items":['true', 'false'],"hide-no-data":""},model:{value:(filter.value),callback:function ($$v) {_vm.$set(filter, "value", $$v)},expression:"filter.value"}}):_vm._e()],1):_c('div',[_c('v-text-field',{staticClass:"text-[13px]",attrs:{"outlined":"","dense":"","hide-details":"","disabled":""},model:{value:(filter.value),callback:function ($$v) {_vm.$set(filter, "value", $$v)},expression:"filter.value"}})],1)])]),(index !== _vm.filterData.length - 1)?_c('div',{staticClass:"mt-3 font-semibold text-gray-500 text-[10px] text-center"},[_vm._v(" AND ")]):_vm._e()])}),0)]),_c('v-card-actions',[_c('div',{staticClass:"flex items-center justify-between"},[_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){_vm.visible = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","disabled":_vm.isSubmitBtnDisabled},on:{"click":_vm.submitFilterData}},[_vm._v(" Submit ")])],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }