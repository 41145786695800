<template>
  <div v-if="tags">
    <div class="grid grid-cols-4 gap-2">
      <div v-for="(item, index) in standartActTags" :key="index" class="mb-3">
        <div
          class="border flex items-center h-full pl-2 min-h-[40px] rounded border-gray-400"
          v-if="item.inputType === 'boolean'"
        >
          <v-checkbox
            dense
            :label="computedLabel(item.label)"
            hide-details
            v-model="item.currentValue"
          >
          </v-checkbox>
        </div>

        <v-text-field
          v-if="['string', 'number'].includes(item.inputType)"
          :label="computedLabel(item.label)"
          outlined
          v-model="item.currentValue"
          :type="item.inputType === 'number' ? 'number' : ''"
          dense
          hide-details
          clearable
          class="text-[13px]"
        ></v-text-field>

        <v-combobox
          v-if="item.inputType.endsWith('[]')"
          clearable
          dense
          :multiple="item.multipleAllowed"
          outlined
          hide-details
          :small-chips="item.multipleAllowed"
          v-model="item.currentValue"
          :items="getComputedItems(item)"
          :label="computedLabel(item.label)"
          max-height="56px"
          class="text-[13px]"
        >
          <template v-slot:selection="data" v-if="item.multipleAllowed">
            <v-chip
              close
              small
              label
              color="#E3F2FD"
              text-color="#0D47A1"
              v-if="data.index < 1"
            >
              {{ data.item }}
            </v-chip>
            <span
              v-if="data.index === 1"
              class="text-grey text-caption align-self-center"
            >
              (+{{ item.currentValue.length - 1 }} others)
            </span>
          </template>
          <template v-slot:no-data>
            <div class="text-[12px] p-4 font-semibold">
              No results matching. If you press <kbd>Enter</kbd>, the value will
              be added to database. Please do not add unnecessary values.
            </div>
          </template>
        </v-combobox>

        <div class="flex items-center" v-if="item.inputType === 'color'">
          <div
            v-if="isColorValid(item.currentValue)"
            class="color-container"
            :style="{ backgroundColor: `#${item.currentValue}` }"
          ></div>
          <v-text-field
            :label="computedLabel(item.label)"
            v-model="item.currentValue"
            ref="cta_text_color"
            hide-details
            placeholder="Ex. f2f2f2"
            :rules="[rules.hex]"
            outlined
            clearable
            class="text-[13px]"
            dense
          ></v-text-field>
        </div>
      </div>
    </div>
    <div>
      <fieldset class="border rounded p-5">
        <legend class="text-gray-600 font-semibold text-[12px] px-2.5">
          Background Sound
        </legend>
        <div>
          <v-radio-group
            style="font-size: 12px"
            v-model="background_sound_choice"
            row
            dense
            hide-details
          >
            <v-radio
              label="Use existing sound"
              value="use_existing_sound"
            ></v-radio>
            <v-radio label="Add new sound" value="add_new_sound"></v-radio>
          </v-radio-group>
        </div>
        <div
          class="mt-3"
          v-if="background_sound_choice === 'use_existing_sound'"
        >
          <v-autocomplete
            clearable
            hide-details
            class="text-[13px]"
            item-text="title"
            item-value="id"
            label="Background Sound"
            outlined
            dense
            :items="general_background_audios"
            v-model="background_selected_sound"
          >
            <template v-slot:item="{ item, on, attrs }">
              <v-list-item>
                <v-list-item-action>
                  <v-icon dense @click="listenBackgroundAudio(item)">
                    {{ item.play ? "mdi-pause" : "mdi-play" }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content
                  v-on="on"
                  v-bind="attrs"
                  class="p-2 cursor-pointer hover:bg-gray-100 rounded ml-2"
                  style="padding: 14px 0 14px 12px"
                >
                  <v-list-item-title>
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-autocomplete>
        </div>
        <div v-else>
          <div class="mt-3">
            <v-text-field
              label="Background Sound URL"
              v-model="background_sound_url"
              outlined
              hide-details
              :rules="[rules.url]"
              class="text-[13px]"
              dense
            ></v-text-field>
          </div>
          <div class="mt-3">
            <v-text-field
              label="Title"
              v-model="background_sound_title"
              hide-details
              outlined
              class="text-[13px]"
              dense
            ></v-text-field>
          </div>
          <div class="mt-3">
            <v-combobox
              clearable
              dense
              multiple
              outlined
              hide-details
              small-chips
              hide-selected
              v-model="background_sound_tags"
              label="Background Sound Tags"
              class="text-[13px]"
            >
              <template v-slot:selection="data">
                <v-chip
                  close
                  small
                  label
                  color="#E3F2FD"
                  text-color="#0D47A1"
                  @click:close="deleteChip(data.item, 'background_sound_tags')"
                >
                  {{ data.item }}
                </v-chip>
              </template>
            </v-combobox>
          </div>
          <div class="mt-3">
            <v-file-input
              show-size
              accept="audio/mp3, audio/wav"
              label="Choose sound"
              class="text-[13px]"
              outlined
              dense
              hide-details
              v-model="background_sound_file"
            ></v-file-input>
          </div>
        </div>
      </fieldset>
    </div>
    <div class="mt-3">
      <fieldset class="border rounded p-5">
        <legend class="text-gray-600 font-semibold text-[12px] px-2.5">
          Reaction Sound
        </legend>
        <div>
          <v-radio-group
            style="font-size: 12px"
            v-model="reaction_sound_choice"
            row
            dense
            hide-details
          >
            <v-radio
              label="Use existing sound"
              value="use_existing_sound"
            ></v-radio>
            <v-radio label="Add new sound" value="add_new_sound"></v-radio>
          </v-radio-group>
        </div>
        <div class="mt-3" v-if="reaction_sound_choice === 'use_existing_sound'">
          <v-autocomplete
            clearable
            hide-details
            class="text-[13px]"
            item-text="title"
            item-value="id"
            label="Reaction Sound"
            outlined
            dense
            :items="general_reaction_audios"
            v-model="reaction_selected_sound"
          >
            <template v-slot:item="{ item, on, attrs }">
              <v-list-item>
                <v-list-item-action>
                  <v-icon dense @click="listenReactionAudio(item)">
                    {{ item.play ? "mdi-pause" : "mdi-play" }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content
                  v-on="on"
                  v-bind="attrs"
                  class="p-2 cursor-pointer hover:bg-gray-100 rounded ml-2"
                  style="padding: 14px 0 14px 12px"
                >
                  <v-list-item-title>
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-autocomplete>
        </div>
        <div v-else>
          <div class="mt-3">
            <v-text-field
              label="Reaction Sound URL"
              v-model="reaction_sound_url"
              hide-details
              :rules="[rules.url]"
              outlined
              class="text-[13px]"
              dense
            ></v-text-field>
          </div>
          <div class="mt-3">
            <v-text-field
              label="Title"
              v-model="reaction_sound_title"
              hide-details
              outlined
              class="text-[13px]"
              dense
            ></v-text-field>
          </div>
          <div class="mt-3">
            <v-combobox
              clearable
              dense
              multiple
              outlined
              hide-details
              small-chips
              hide-selected
              v-model="reaction_sound_tags"
              label="Reaction Sound Tags"
              class="text-[13px]"
            >
              <template v-slot:selection="data">
                <v-chip
                  close
                  small
                  label
                  color="#E3F2FD"
                  text-color="#0D47A1"
                  @click:close="deleteChip(data.item, 'reaction_sound_tags')"
                >
                  {{ data.item }}
                </v-chip>
              </template>
            </v-combobox>
          </div>
          <div class="mt-3">
            <v-file-input
              show-size
              accept="audio/mp3, audio/wav"
              label="Choose sound"
              class="text-[13px]"
              outlined
              dense
              hide-details
              v-model="reaction_sound_file"
            ></v-file-input>
          </div>
        </div>
      </fieldset>
    </div>
    <!-- <div class="mt-3 flex items-center">
      <v-select
        hide-details
        class="text-[13px]"
        item-text="name"
        item-value="filename"
        label="Pointer"
        return-object
        outlined
        dense
        :items="point_of_views"
        v-model="pointer"
      >
        <template v-slot:item="{ item, attrs, on }">
          <v-list-item v-on="on" v-bind="attrs">
            <v-list-item-avatar v-if="item.direct_link">
              <v-img :src="item.direct_link"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.name }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-select>

      <div class="ml-2">
        <v-btn
          text
          color="primary"
          @click="show_upload_point_of_view = true"
          :loading="add_new_loading"
          :disabled="add_new_loading"
        >
          Add New
        </v-btn>
      </div>
    </div> -->
  </div>
</template>

<script>
import { ACT_STANDART_TAGS } from "@/helper/utils";
import _ from "lodash";

export default {
  props: {
    defaultGameGenre: {
      type: Array,
      default: () => [],
    },
    tags: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      extra_data: null,
      background_sound_choice: "use_existing_sound",
      background_selected_sound: null,
      background_sound_tags: [],
      background_sound_url: null,
      reaction_sound_choice: "use_existing_sound",
      reaction_selected_sound: null,
      reaction_sound_tags: [],
      reaction_sound_url: null,
      general_background_audios: [],
      general_reaction_audios: [],
      reaction_sound_title: null,
      background_sound_title: null,
      reaction_sound_file: null,
      background_sound_file: null,
      point_of_views: [],
      show_upload_point_of_view: false,
      pointer: null,
      current_game_tags: [],
      voice_over_status_choices: [
        {
          id: true,
          name: "Existent",
        },
        {
          id: false,
          name: "Non-Existent",
        },
      ],
      rules: {
        hex: (value) => {
          const pattern = /^([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/;
          return pattern.test(value) || "Please enter a valid hex color.";
        },
        url: (value) => {
          const pattern = new RegExp(
            /^(https?:\/\/)?([\da-z\.-]+\.[a-z\.]{2,6}|[\d\.]+)([\/:?=&#]{1}[\da-z\.-]+)*[\/\?]?$/gim // eslint-disable-line
          );
          return pattern.test(value) || "Please enter a valid URL.";
        },
      },
      pointer_choices: [
        {
          id: null,
          name: "None",
        },
        {
          id: "real-hand",
          name: "Real Hand",
        },
        {
          id: "realistic-hand-icon",
          name: "Realistic Hand Icon",
        },
        {
          id: "drawn-hand-icon",
          name: "Drawn Hand Icon",
        },
      ],
      standartActTags: null,
    };
  },
  watch: {
    background_sound_choice() {
      this.background_selected_sound = null;
      this.background_sound_tags = [];
      this.background_sound_url = null;
    },
    reaction_sound_choice() {
      this.reaction_selected_sound = null;
      this.reaction_sound_tags = [];
      this.reaction_sound_url = null;
    },
    computedRequestData: {
      handler(newValue) {
        this.$emit("formChanged", newValue);
      },
      deep: true,
    },
  },
  methods: {
    checkIfURLValid(url) {
      const pattern = new RegExp(
        /^(https?:\/\/)?([\da-z\.-]+\.[a-z\.]{2,6}|[\d\.]+)([\/:?=&#]{1}[\da-z\.-]+)*[\/\?]?$/gim // eslint-disable-line
      );
      return pattern.test(url);
    },
    fetchGeneralAudios() {
      this.$api.get("act/general-audio/all").then((response) => {
        this.general_background_audios = response.data
          .filter((x) => x.type === "background")
          .map((x) => ({
            ...x,
            play: false,
            sound: null,
          }));
        this.general_reaction_audios = response.data
          .filter((x) => x.type === "reaction")
          .map((x) => ({
            ...x,
            play: false,
            sound: null,
          }));
      });
    },
    fetchPointOfViews() {
      this.$api.get("act/point-of-view/all").then((response) => {
        this.point_of_views = response.data;
      });
    },
    sortedData(data) {
      return data.sort(function (a, b) {
        var label_a = a.label.toUpperCase();
        var label_b = b.label.toUpperCase();

        return label_a.localeCompare(label_b);
      });
    },
    computedLabel(label) {
      return _.startCase(_.toLower(label));
    },
    listenBackgroundAudio(item) {
      const idx = this.general_background_audios.findIndex(
        (x) => x.id === item.id
      );
      // Stop audios
      for (let i = 0; i < this.general_background_audios.length; i++) {
        if (i !== idx) {
          this.general_background_audios[i].play = false;
          if (this.general_background_audios[i].sound) {
            this.general_background_audios[i].sound.pause();
          }
        }
      }
      if (this.general_background_audios[idx].play) {
        this.general_background_audios[idx].play = false;
        this.general_background_audios[idx].sound.pause();
      } else {
        this.general_background_audios[idx].sound = new Audio(item.direct_link);
        this.general_background_audios[idx].play = true;
        this.general_background_audios[idx].sound.play();
      }
    },
    listenReactionAudio(item) {
      const idx = this.general_reaction_audios.findIndex(
        (x) => x.id === item.id
      );
      // Stop audios
      for (let i = 0; i < this.general_reaction_audios.length; i++) {
        if (i !== idx) {
          this.general_reaction_audios[i].play = false;
          if (this.general_reaction_audios[i].sound) {
            this.general_reaction_audios[i].sound.pause();
          }
        }
      }
      if (this.general_reaction_audios[idx].play) {
        this.general_reaction_audios[idx].play = false;
        this.general_reaction_audios[idx].sound.pause();
      } else {
        this.general_reaction_audios[idx].sound = new Audio(item.direct_link);
        this.general_reaction_audios[idx].play = true;
        this.general_reaction_audios[idx].sound.play();
      }
    },
    isColorValid(color) {
      const pattern = /^([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/;
      return pattern.test(color);
    },
    getComputedItems(item) {
      const item_key = item.key;
      const filteredTag = this.tags.filter((x) => x.name === item_key);

      if (item.key === "point_of_view") {
        return [...Array(54).keys()].map((x) => x + 1);
      }

      if (!filteredTag.length) {
        return [];
      } else {
        return JSON.parse(filteredTag[0].info);
      }
    },
  },
  computed: {
    computedRequestData() {
      // Copy form data to another variable.
      // if (!this.computedBackgroundSound) return null;
      // if (!this.computedReactionSound) return null;

      if (this.standartActTags) {
        const data = this.standartActTags.slice();

        // Modify this variable as a request data.
        var requestData = {};

        for (let i = 0; i < data.length; i++) {
          const element = data[i];
          // eslint-disable-next-line
          // Add into color variables for Eray's creative analysis.
          if (element.inputType === "color") {
            requestData[element.key] = element.currentValue
              ? `#${element.currentValue}`
              : null;
          } else {
            requestData[element.key] = element.currentValue;
          }
        }

        requestData["backgroundSound"] = this.computedBackgroundSound;
        requestData["reactionSound"] = this.computedReactionSound;

        return requestData;
      }
      return null;
    },
    computedActStandartTags() {
      return ACT_STANDART_TAGS;
    },
    computedStyleTags() {
      if (this.tags && this.tags.length > 0) {
        let style_tags = this.tags.some((x) => x.name === "style");

        if (style_tags) {
          let tags = this.tags.filter((x) => x.name === "style")[0];
          if (tags.info) {
            return JSON.parse(tags.info);
          }
        }
        return null;
      }
      return null;
    },
    computedThemeTags() {
      if (this.tags && this.tags.length > 0) {
        let theme_tags = this.tags.some((x) => x.name === "theme");

        if (theme_tags) {
          let tags = this.tags.filter((x) => x.name === "theme")[0];
          if (tags.info) {
            return JSON.parse(tags.info);
          }
        }
        return null;
      }
      return null;
    },
    computedTriggerinEmotionTags() {
      if (this.tags && this.tags.length > 0) {
        let triggering_emotion_tags = this.tags.some(
          (x) => x.name === "triggering_emotion"
        );

        if (triggering_emotion_tags) {
          let tags = this.tags.filter(
            (x) => x.name === "triggering_emotion"
          )[0];
          if (tags.info) {
            return JSON.parse(tags.info);
          }
        }
        return null;
      }
      return null;
    },
    computedKeywordTags() {
      if (this.tags && this.tags.length > 0) {
        let keywords = this.tags.some((x) => x.name === "keywords");

        if (keywords) {
          let tags = this.tags.filter((x) => x.name === "keywords")[0];
          if (tags.info) {
            return JSON.parse(tags.info);
          }
        }
        return null;
      }
      return null;
    },
    computedGameGenreTags() {
      if (this.tags && this.tags.length > 0) {
        let game_genres = this.tags.some((x) => x.name === "game_genre");

        if (game_genres) {
          let tags = this.tags.filter((x) => x.name === "game_genre")[0];
          if (tags.info) {
            return JSON.parse(tags.info);
          }
        }
        return null;
      }
      return null;
    },
    computedGameActionTags() {
      if (this.tags && this.tags.length > 0) {
        let game_action_tags = this.tags.some((x) => x.name === "game_action");

        if (game_action_tags) {
          let tags = this.tags.filter((x) => x.name === "game_action")[0];
          if (tags.info) {
            return JSON.parse(tags.info);
          }
        }
        return null;
      }
      return null;
    },
    computedFonts() {
      if (this.tags && this.tags.length > 0) {
        let headline_text_font = this.tags.some(
          (x) => x.name === "headline_text_font"
        );

        if (headline_text_font) {
          let tags = this.tags.filter(
            (x) => x.name === "headline_text_font"
          )[0];

          return JSON.parse(tags.info);
        }
        return null;
      }
      return null;
    },
    computedBackgroundSound() {
      if (this.background_sound_choice === "use_existing_sound") {
        if (this.background_selected_sound) {
          return {
            background_selected_sound: this.background_selected_sound,
          };
        }
        return false;
      } else {
        if (
          this.background_sound_url &&
          this.checkIfURLValid(this.background_sound_url) &&
          this.background_sound_tags.length > 0 &&
          this.background_sound_title &&
          this.background_sound_file
        ) {
          return {
            background_sound_url: this.background_sound_url,
            background_sound_tags: this.background_sound_tags,
            background_sound_title: this.background_sound_title,
            background_sound_file: this.background_sound_file,
          };
        }
        return false;
      }
    },
    computedReactionSound() {
      if (this.reaction_sound_choice === "use_existing_sound") {
        if (this.reaction_selected_sound) {
          return {
            reaction_selected_sound: this.reaction_selected_sound,
          };
        }
        return false;
      } else {
        if (
          this.reaction_sound_url &&
          this.checkIfURLValid(this.reaction_sound_url) &&
          this.reaction_sound_tags.length > 0 &&
          this.reaction_sound_title &&
          this.reaction_sound_file
        ) {
          return {
            reaction_sound_url: this.reaction_sound_url,
            reaction_sound_tags: this.reaction_sound_tags,
            reaction_sound_title: this.reaction_sound_title,
            reaction_sound_file: this.reaction_sound_file,
          };
        }
        return false;
      }
    },
  },
  mounted() {
    this.standartActTags = this.sortedData(this.computedActStandartTags).map(
      (x) => {
        return {
          ...x,
          currentValue: x.key !== "game_genre" ? null : this.defaultGameGenre,
        };
      }
    );

    this.fetchGeneralAudios();
    this.fetchPointOfViews();
  },
};
</script>

<style lang="scss" scoped>
.color-container {
  width: 40px;
  border-radius: 4px;
  height: 40px;
  margin-right: 12px;
}
</style>
