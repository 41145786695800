<template>
  <div v-if="getUserProfile">
    <div class="bg-white p-6">
      <div class="flex items-center justify-between pb-6">
        <h1 class="font-semibold text-[18px]">Automatized Creative Testing</h1>
        <div>
          <v-btn
            @click="oauthSignIn"
            :disabled="computedUserCredential"
            color="primary"
            depressed
          >
            <v-icon left dark> mdi-google-drive</v-icon>
            Connect Drive
          </v-btn>
        </div>
      </div>
      <div
        v-if="
          [1, 5].includes(getUserProfile.department.id) &&
          computedUserCredential
        "
      >
        <div
          v-if="(result && result.success) || (result && result.id)"
          class="p-6 rounded border border-green-200 bg-green-50 text-[12px] mb-5 text-green-800 font-semibold"
        >
          Creative has been successfully created.
        </div>
        <div>
          <v-select
            hide-details
            class="text-[13px]"
            item-text="title"
            label="Choose a game"
            :loading="loading"
            :disabled="loading || selected_base_creative !== null"
            outlined
            dense
            :items="games"
            v-model="artist_selected_game"
            return-object
          >
          </v-select>
        </div>

        <div class="mt-5">
          <v-select
            hide-details
            :disabled="loading || selected_base_creative !== null"
            class="text-[13px]"
            item-text="title"
            item-value="id"
            label="Upload type"
            :loading="loading"
            outlined
            dense
            :items="artist_upload_types"
            v-model="artist_upload_type"
          ></v-select>
        </div>

        <div>
          <div class="mt-5">
            <v-text-field
              label="Creative Name"
              v-model="creative_name"
              hide-details
              outlined
              :loading="loading"
              :disabled="loading || selected_base_creative !== null"
              class="text-[13px]"
              dense
            ></v-text-field>
          </div>

          <div class="mt-5">
            <v-autocomplete
              clearable
              label="Choose a language"
              outlined
              auto-select-first
              dense
              :loading="loading"
              :disabled="loading || selected_base_creative !== null"
              class="text-[13px]"
              v-model="selected_language"
              :items="references"
              item-text="name"
              item-value="iso_lower"
              hide-details
            >
            </v-autocomplete>
          </div>
          <!-- Note: .inputtrace file ONLY required if game is not Physics Based. In Physics based games, we use different system for ACG. -->
          <div
            class="mt-5"
            v-if="
              artist_selected_game &&
              artist_selected_game.acg_type !== 'PHYSICS_BASED'
            "
          >
            <v-file-input
              show-size
              accept=".inputtrace"
              label="Choose trace file"
              class="text-[13px]"
              outlined
              :loading="loading"
              :disabled="loading || selected_base_creative !== null"
              dense
              hide-details
              v-model="trace_file"
            >
            </v-file-input>
          </div>

          <div class="mt-5">
            <v-file-input
              show-size
              accept="application/json"
              label="Parameters json file"
              class="text-[13px]"
              outlined
              :loading="loading"
              :disabled="loading || selected_base_creative !== null"
              dense
              hide-details
              v-model="parameters_file"
            >
            </v-file-input>
          </div>

          <div class="mt-5">
            <v-file-input
              multiple
              show-size
              accept="video/mp4"
              label="Choose video(s)"
              class="text-[13px]"
              outlined
              :loading="loading"
              :disabled="loading || selected_base_creative !== null"
              dense
              hide-details
              v-model="marketing_artist_videos"
            >
            </v-file-input>
          </div>

          <div class="mt-5">
            <VideoSlider
              :videos="marketing_artist_videos"
              @delete_video="handleOnDeleteVideo"
            />
          </div>
        </div>
        <div
          v-if="
            artist_selected_game &&
            artist_upload_type &&
            creative_name &&
            selected_language &&
            marketing_artist_videos.length > 0 &&
            computedErrors.length === 0 &&
            !selected_base_creative
          "
        >
          <div class="my-5">
            <v-divider></v-divider>
          </div>
          <h1 class="font-semibold text-[13px] mb-5">1. General Metadata</h1>
          <StandartTags
            @formChanged="handleStandartTagFormChange"
            :defaultGameGenre="artist_selected_game.computedGenres"
            :tags="this.tags"
          />
        </div>

        <div class="my-5 flex items-center justify-end">
          <v-btn
            :disabled="
              (!selected_base_creative &&
                (isMarketingBtnDisabled || loading)) ||
              (selected_base_creative && isMarketingBtnDisabled)
            "
            :loading="loading"
            color="primary"
            @click.native="handleSubmitButton"
            >Upload
          </v-btn>
        </div>
      </div>
      <div v-else>
        <v-alert
          text
          type="error"
          prominent
          icon="mdi-alert-circle-outline"
          border="left"
        >
          <div class="text-[12px] p-2 font-semibold">
            You do not have required permission or you need to connect your
            drive account (if you are a marketing artist).
          </div>
        </v-alert>
      </div>
    </div>

    <ErrorDialog
      :videos="marketing_artist_videos"
      :show="computedErrors.length > 0"
      :errors="computedErrors"
      @closed="handleErrorDialogClosed"
    ></ErrorDialog>
    <UploadPointOfView
      @closed="handleUploadPointOfViewClosed"
      :show="show_upload_point_of_view"
      @submitted="handleUploadPointOfViewSubmitted"
    />
  </div>
</template>

<script>
import Cookies from "js-cookie";
import { mapGetters, mapActions } from "vuex";
import VideoSlider from "@/components/tools/ccc/facebook/campaign-create-view/VideoSlider.vue";
import ErrorDialog from "@/components/tools/ccc/facebook/campaign-create-view/ErrorDialog.vue";
import UploadPointOfView from "@/components/tools/act/UploadPointOfView.vue";
import _ from "lodash";
import StandartTags from "@/components/tools/act/StandartTags.vue";

export default {
  name: "ActView",
  data() {
    return {
      credentials: [],
      test: null,
      add_new_loading: false,
      games: [],
      artist_selected_game: null,
      artist_upload_types: [
        {
          id: 1,
          title: "Video Creative Set",
        },
      ],
      artist_upload_type: null,
      creative_name: "",
      selected_language: null,
      marketing_artist_videos: [],
      result: null,
      loading: false,
      dimension_errors: [],
      regex_errors: [],
      references: [],
      tags: null,
      base_creatives: [],
      selected_base_creative: null,
      asset_file: null,
      show_upload_point_of_view: null,
      standartTagData: null,
      trace_file: null,
      parameters_file: null,
      replayFile: null,
      replayTextFile: null,
    };
  },
  methods: {
    ...mapActions(["setExtraData"]),
    async handleSignedUpload(creative_id) {
      if (this.replayFile) {
        const signedUrl = await this.createSignedUrl(
          this.replayFile.name,
          creative_id
        );
        const uploadResult = await this.uploadToSignedUrl(
          signedUrl,
          this.replayFile
        );
        return uploadResult;
      }
    },
    async createSignedUrl(fileName, creative_id) {
      try {
        this.loading = true;
        const response = await this.$api.post(
          `utils/signed-url?filename=${fileName}&creative_id=${creative_id}`
        );
        if (response.status === 200) {
          return response.data;
        }
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.loading = false;
      }
    },
    async uploadToSignedUrl(signed_url, file) {
      try {
        this.loading = true;
        const response = await this.$makeXMLRequest(signed_url, file);
        if (response) {
          return true;
        } else {
          return false;
        }
      } catch (error) {
        this.errorHandler(error);
        return false;
      } finally {
        this.loading = false;
      }
    },
    async fetchCredentials() {
      try {
        const response = await this.$api.get("utils/drive-credentials");
        this.credentials = response.data;
      } catch (error) {
        this.errorHandler(error);
      }
    },
    oauthSignIn() {
      const gaia_sid = Cookies.get("gaia_sid");
      if (!gaia_sid) {
        window.reload();
      }

      // Google's OAuth 2.0 endpoint for requesting an access token
      var oauth2Endpoint = "https://accounts.google.com/o/oauth2/v2/auth";

      // Create <form> element to submit parameters to OAuth 2.0 endpoint.
      var form = document.createElement("form");
      form.setAttribute("method", "GET"); // Send as a GET request.
      form.setAttribute("action", oauth2Endpoint);

      // Parameters to pass to OAuth 2.0 endpoint.
      var params = {
        client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
        redirect_uri: process.env.VUE_APP_OAUTH_DRIVE_REDIRECT_URL,
        response_type: "code",
        scope: "https://www.googleapis.com/auth/drive",
        include_granted_scopes: true,
        state: Cookies.get("gaia_sid"),
        access_type: "offline",
        prompt: "consent select_account",
      };

      // Add form parameters as hidden input values.
      for (var p in params) {
        var input = document.createElement("input");
        input.setAttribute("type", "hidden");
        input.setAttribute("name", p);
        input.setAttribute("value", params[p]);
        form.appendChild(input);
      }

      // Add form to page and submit it to open the OAuth 2.0 endpoint.
      document.body.appendChild(form);
      form.submit();
    },
    isTagVisible(idx) {
      const tag = this.artist_selected_game.computedTags[idx];
      if (tag.depends_on) {
        // 1- Find the dependent tag.
        var dependent_tag = this.artist_selected_game.computedTags.filter(
          (x) => x.label === tag.depends_on
        );
        if (!dependent_tag.length) return false;
        dependent_tag = dependent_tag[0];

        // 2- Check if dependent values are included in dependent_tag's current Value...
        const targetValues = dependent_tag.currentValue;
        if (!targetValues) {
          return false;
        }

        const dependent_values = tag.dependent_values
          .split(",")
          .map((x) => x.trim());

        for (let i = 0; i < dependent_values.length; i++) {
          const dependent_value = dependent_values[i];
          const isArr = this.$isArr(targetValues);

          if (!isArr) {
            if (dependent_value == targetValues) {
              return true;
            }

            if (["true", "false"].includes(dependent_value)) {
              if (dependent_value === "true" && targetValues === true)
                return true;
              if (dependent_value === "false" && targetValues === false)
                return true;
            }
          } else {
            for (let y = 0; y < targetValues.length; y++) {
              const target_value = targetValues[y];
              if (dependent_value === target_value) return true;
            }
          }
        }

        return false;
      }
      return true;
    },
    handleStandartTagFormChange(data) {
      this.standartTagData = data;
    },
    computedLabel(label) {
      return _.startCase(_.toLower(label));
    },
    handleUploadPointOfViewSubmitted(data) {
      this.add_new_loading = true;

      let formData = new FormData();
      formData.append("pointer_file", data.pointer_file);
      formData.append("title", data.title);
      formData.append("point_type", data.point_type);

      this.$api
        .post("act/point-of-view/create", formData)
        .then((response) => {
          if (response.status === 200) {
            this.fetchPointOfViews();
            this.$notify({
              type: "success_",
              group: "success_",
              text: "Success",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.add_new_loading = false;
        });
    },
    handleUploadPointOfViewClosed() {
      this.show_upload_point_of_view = false;
    },

    fetchGames() {
      this.$api
        .get("games/all?only_actives=True")
        .then((response) => {
          this.games = response.data;

          for (let i = 0; i < this.games.length; i++) {
            const element = this.games[i];
            if (element.computedTags.length) {
              this.games[i].computedTags = this.games[i].computedTags.map(
                (x) => {
                  return {
                    ...x,
                    currentValue: x.type !== "boolean" ? null : false,
                  };
                }
              );
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fetchReferences() {
      this.$api
        .get("references/all")
        .then((response) => {
          this.references = response.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fetchTags() {
      this.$api
        .get("tags/all")
        .then((response) => {
          this.tags = response.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleOnDeleteVideo(name) {
      this.marketing_artist_videos = this.marketing_artist_videos.filter(
        (x) => x.name !== name
      );
    },
    modifyGameData(data) {
      const keys = Object.keys(data);
      var temp = {};

      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        if (key !== "undergroundDesignData") {
          temp[key] = data[key].variableValue;
        }
      }

      return temp;
    },
    async uploadFiles() {
      if (
        this.marketing_artist_videos.length > 0 &&
        !this.selected_base_creative &&
        this.parameters_file
      ) {
        var game_spesific_metadata = await this.$readJsonFile(
          this.parameters_file
        );

        game_spesific_metadata = this.modifyGameData(game_spesific_metadata);

        const metadata = { ...this.standartTagData };

        delete metadata.reactionSound;
        delete metadata.backgroundSound;

        let formData = new FormData();
        for (let i = 0; i < this.marketing_artist_videos.length; i++) {
          formData.append("files", this.marketing_artist_videos[i]);
        }
        formData.append("title", this.creative_name);
        formData.append("language", this.selected_language);
        if (this.trace_file) formData.append("trace_file", this.trace_file);
        formData.append("parameters_file", this.parameters_file);
        formData.append("selected_game_id", this.artist_selected_game.id);
        if (
          this.standartTagData.backgroundSound &&
          this.standartTagData.backgroundSound.background_sound_file
        ) {
          formData.append(
            "background_sound_file",
            this.standartTagData.backgroundSound.background_sound_file
          );
        }
        if (
          this.standartTagData.reactionSound &&
          this.standartTagData.reactionSound.reaction_sound_file
        ) {
          formData.append(
            "reaction_sound_file",
            this.standartTagData.reactionSound.reaction_sound_file
          );
        }
        formData.append("standart_metadata", JSON.stringify(metadata));
        formData.append(
          "game_spesific_metadata",
          JSON.stringify(game_spesific_metadata)
        );
        formData.append(
          "background_sound",
          JSON.stringify(this.standartTagData.backgroundSound)
        );
        formData.append(
          "reaction_sound",
          JSON.stringify(this.standartTagData.reactionSound)
        );

        try {
          this.loading = true;

          const request = await this.$api.post("act/assets/create", formData);
          this.result = request.data;
          // const signedUploads = await this.handleSignedUpload(request.data.id);
          // if (signedUploads) {
          //   this.$toast.success("All files successfully uploaded.", {
          //     timeout: 5000,
          //     position: "bottom-center",
          //     icon: false,
          //   });

          //   await this.updateCreativeReplayInformation();
          // }
          // this.artist_selected_game = null;

          this.$toast.success("All files successfully uploaded.", {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });

          this.artist_selected_game = null;
        } catch (error) {
          this.errorHandler(error);
        } finally {
          this.loading = false;
        }
      }
    },
    async updateCreativeReplayInformation() {
      try {
        const response = await this.$api.post(
          `act/general-creatives/update-replay-files?replayFileName=${this.replayFile.name}&creative_id=${this.result.id}`
        );
        if (response.status === 200) {
          return true;
        }
      } catch (error) {
        this.errorHandler(error);
      }
    },
    handleSubmitButton() {
      this.uploadFiles();
    },
    isFilenameIncluded(filename) {
      const types = ["1x1", "4x5", "9x16", "16x9"];

      let includes = false;
      for (let i = 0; i < types.length; i++) {
        const pattern = `^(${types[i]}_[a-zA-Z0-9]+(?:_[0-9]+)?.mp4)`;
        const regex = new RegExp(pattern);

        if (regex.test(filename)) {
          includes = true;
        }
      }

      return includes;
    },
    setDimensionErrors() {
      if (
        this.marketing_artist_videos &&
        this.marketing_artist_videos.length > 0
      ) {
        for (let i = 0; i < this.marketing_artist_videos.length; i++) {
          const file = this.marketing_artist_videos[i];
          const url = URL.createObjectURL(file);
          const $video = document.createElement("video");
          $video.src = url;

          const pushDimensionError = (error) => {
            this.dimension_errors.push(error);
          };

          $video.addEventListener("loadedmetadata", function () {
            const ratio = 4 / 5;
            const ratio2 = 9 / 16;
            const ratio4 = 16 / 9;
            const ratio5 = 1 / 1;
            const video_ratio = this.videoWidth / this.videoHeight;
            let file_type = null;
            if (file.name.startsWith("1x1")) {
              file_type = "1x1";
            }
            if (file.name.startsWith("4x5")) {
              file_type = "4x5";
            }
            if (file.name.startsWith("9x16")) {
              file_type = "9x16";
            }
            if (file.name.startsWith("16x9")) {
              file_type = "16x9";
            }

            if (file_type == "4x5" && video_ratio !== ratio) {
              pushDimensionError({
                detail: `<span class='font-semibold'>${file.name}</span> is not within suggested ratio.`,
                solution: `Width-Height ratio must be 4x5.`,
              });
            }

            if (file_type == "9x16" && video_ratio !== ratio2) {
              pushDimensionError({
                detail: `<span class='font-semibold'>${file.name}</span> is not within suggested ratio.`,
                solution: `Width-Height ratio must be 9x16.`,
              });
            }

            if (file_type == "16x9" && video_ratio !== ratio4) {
              pushDimensionError({
                detail: `<span class='font-semibold'>${file.name}</span> is not within suggested ratio.`,
                solution: `Width-Height ratio must be 16x9.`,
              });
            }

            if (file_type == "1x1" && video_ratio !== ratio5) {
              pushDimensionError({
                detail: `<span class='font-semibold'>${file.name}</span> is not within suggested ratio.`,
                solution: `Width-Height ratio must be 1x1.`,
              });
            }

            if ($video.duration < 3) {
              pushDimensionError({
                detail: `<span class='font-semibold'>${file.name} is too short. There might be an error occured while rendering.</span>`,
                solution: `Video duration must be larger than 3 seconds.`,
              });
            }
          });
        }

        const names = this.marketing_artist_videos.map((x) => x.name);
        const types = ["1x1", "4x5", "9x16", "16x9"];

        for (let i = 0; i < types.length; i++) {
          const type = types[i];
          let includes = false;
          for (let y = 0; y < names.length; y++) {
            const name = names[y];
            if (name.startsWith(type)) {
              includes = true;
            }
          }
          if (!includes) {
            this.dimension_errors.push({
              detail: `There is no <span class='font-semibold'>${type}</span> video selected. (Consider naming rules)`,
              solution: `Be sure to choose ${type} video.`,
            });
          }
        }
      }
    },
    setRegexErros() {
      if (
        this.marketing_artist_videos &&
        this.marketing_artist_videos.length > 0
      ) {
        for (let i = 0; i < this.marketing_artist_videos.length; i++) {
          const file_name = this.marketing_artist_videos[i].name;
          if (!this.isFilenameIncluded(file_name)) {
            this.regex_errors.push({
              detail: `<b>${file_name}</b> is not within the suggested name format or extension.`,
              solution: `File name must be in following format and all vids must be an <b>mp4</b>. <br><br> <span class='font-semibold'>(Width)x(Height)_ConceptName.mp4 (Not in pixels, in Ratio)</span><br> <br> <span class='font-semibold'>Ex:</span> 9x16_ConceptName.mp4, 4x5_ConceptName.mp4...`,
            });
          }
        }
      }
    },
    handleErrorDialogClosed() {
      this.marketing_artist_videos = [];
      this.regex_errors = [];
      this.dimension_errors = [];
    },
    fetchGameTags() {
      this.$api
        .get(`games/${this.artist_selected_game.id}`)
        .then((response) => {
          this.current_game_tags = response.data.genres
            ? JSON.parse(response.data.genres)
            : [];
        });
    },
    fetchGameTagsForBaseCreative(id) {
      this.$api.get(`acg/base-creatives/${id}/game-info`).then((response) => {
        this.current_game_tags = response.data.genres
          ? JSON.parse(response.data.genres)
          : [];
      });
    },
    errorHandler(error) {
      if ("response" in error) {
        if ("data" in error["response"]) {
          const isArr = this.$isArr(error["response"]["data"]["detail"]);
          var errorMessage = error["response"]["data"]["detail"];
          if (isArr) {
            errorMessage = JSON.stringify(errorMessage);
          }

          this.$toast.error(errorMessage, {
            timeout: 0,
            position: "bottom-center",
            icon: false,
          });
        }
        return;
      } else if ("message" in error) {
        this.$toast.error(error["message"], {
          timeout: 0,
          position: "bottom-center",
          icon: false,
        });

        return;
      } else {
        this.$toast.error(error.message, {
          timeout: 0,
          position: "bottom-center",
          icon: false,
        });
        return;
      }
    },
    fetchNotTestedCreatives() {
      this.$api
        .get("acg/base-creatives/all?status_id=5")
        .then((response) => {
          this.base_creatives = response.data;
          this.base_creatives = this.base_creatives.map((x) => {
            return {
              ...x,
              title: `${x.user.full_name} - ${x.title} - (${this.$formatUnix(
                x.created_at
              )})`,
            };
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    ...mapGetters(["getUserProfile", "getExtraData"]),
    computedUserCredential() {
      if (!this.getUserProfile) return null;
      else {
        const user_id = this.getUserProfile.id;
        return this.credentials.some((x) => x.user_id === user_id);
      }
    },
    isMarketingBtnDisabled() {
      if (!this.selected_base_creative) {
        if (!this.artist_selected_game) {
          return true;
        }
        if (!this.artist_upload_type) {
          return true;
        }
        if (this.artist_upload_type === 1) {
          if (!this.creative_name) {
            return true;
          }
          if (this.marketing_artist_videos.length === 0) {
            return true;
          }
          if (!this.selected_language) {
            return true;
          }
          if (!this.standartTagData) return true;
          if (!this.parameters_file) return true;

          // if (this.artist_selected_game.computedTags.length) {
          //   for (
          //     let i = 0;
          //     i < this.artist_selected_game.computedTags.length;
          //     i++
          //   ) {
          //     const element = this.artist_selected_game.computedTags[i];
          //     if (
          //       (element.currentValue === null ||
          //         element.currentValue.length === 0) &&
          //       element.isRequired
          //     ) {
          //       return true;
          //     }
          //   }
          // }
        }

        return false;
      } else {
        if (this.computedAssetBtnDisabled) {
          return true;
        }
        return false;
      }
    },
    computedErrors() {
      return [...this.regex_errors, ...this.dimension_errors];
    },
    computedAssetBtnDisabled() {
      if (this.selected_base_creative) {
        if (!this.asset_file) {
          return true;
        }
        return false;
      }
      return false;
    },
    computedRequestGameData() {
      var game_spesific_metadata = {};

      // Modify Game Spesific Metadata
      for (let i = 0; i < this.artist_selected_game.computedTags.length; i++) {
        const element = this.artist_selected_game.computedTags[i];
        if (this.isTagVisible(i))
          game_spesific_metadata[this.$formatLabelToKey(element.label)] =
            element.currentValue;
        else
          game_spesific_metadata[this.$formatLabelToKey(element.label)] = null;
      }

      return game_spesific_metadata;
    },
  },
  watch: {
    selected_base_creative(newValue) {
      this.artist_selected_game = null;
      this.artist_upload_type = null;
      this.creative_name = null;
      this.marketing_artist_videos = [];
      this.asset_file = null;
      if (newValue) {
        this.fetchGameTagsForBaseCreative(newValue.id);
      }
    },

    marketing_artist_videos(newValue) {
      this.setDimensionErrors(newValue);
      this.setRegexErros(newValue);
    },
    artist_selected_game(newValue) {
      if (newValue) {
        this.fetchGameTags();
        this.creative_name = "";
        this.marketing_artist_videos = [];
        this.standartTagData = null;
      }
    },
  },
  mounted() {
    this.fetchCredentials();
    this.fetchGames();
    this.fetchReferences();
    this.fetchTags();
    this.fetchNotTestedCreatives();
  },
  components: { VideoSlider, ErrorDialog, UploadPointOfView, StandartTags },
};
</script>

<style lang="scss" scoped>
::v-deep .v-btn__content {
  font-size: 13px !important;
  font-weight: 600;
  text-transform: capitalize;
}

::v-deep .v-list-item__title {
  font-size: 13px;
}

::v-deep .v-input--selection-controls.v-input {
  margin-top: 0 !important;
  padding-top: 0 !important;
}

.color-container {
  width: 40px;
  border-radius: 4px;
  height: 40px;
  margin-right: 12px;
}

::v-deep .v-list-item__action {
  margin-right: 0 !important;
}
</style>
