<template>
  <div class="bg-white p-6">
    <h1 class="font-semibold text-[18px] pb-6">Import Ae Project</h1>
    <div>
      <div class="mt-5">
        <v-autocomplete
          clearable
          hide-details
          class="text-[13px]"
          item-text="search_title"
          return-object
          label="Gameplay"
          outlined
          dense
          :items="base_creatives"
          v-model="selected_base_creative"
        ></v-autocomplete>
      </div>

      <div class="mt-5">
        <v-file-input
          show-size
          accept="application/zip"
          label="After Effects Project (Zip)"
          class="text-[13px]"
          outlined
          :loading="loading"
          :disabled="loading"
          dense
          hide-details
          v-model="asset_file"
        >
        </v-file-input>
      </div>
    </div>
    <div class="flex items-center justify-end mt-4">
      <v-btn
        depressed
        :disabled="!this.asset_file || !this.selected_base_creative"
        color="success"
        :loading="loading"
        @click="uploadAsset"
      >
        Upload
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      base_creatives: [],
      selected_base_creative: null,
      asset_file: null,
      loading: false,
    };
  },
  methods: {
    async uploadAsset() {
      if (!this.computedAssetBtnDisabled) {
        let formData = new FormData();
        formData.append("asset", this.asset_file);
        formData.append("base_creative_id", this.selected_base_creative.id);

        try {
          this.loading = true;
          await this.$api.post("acg/base-creatives/test/create", formData);
          this.$toast.success(
            "File successfully uploaded. This gameplay will be rendered soon.",
            {
              timeout: 5000,
              position: "bottom-center",
              icon: false,
            }
          );
        } catch (e) {
          this.errorHandler(e);
        } finally {
          this.loading = false;
        }
      }
    },
    fetchNotTestedCreatives() {
      this.$api
        .get("acg/base-creatives/all?status_id=5")
        .then((response) => {
          this.base_creatives = response.data;
          this.base_creatives = this.base_creatives.map((x) => {
            return {
              ...x,
              title: `${x.user.full_name} - ${x.title} - (${this.$formatUnix(
                x.created_at
              )})`,
            };
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    errorHandler(error) {
      if ("response" in error) {
        if ("data" in error["response"]) {
          const isArr = this.$isArr(error["response"]["data"]["detail"]);
          var errorMessage = error["response"]["data"]["detail"];
          if (isArr) {
            errorMessage = JSON.stringify(errorMessage);
          }

          this.$toast.error(errorMessage, {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });
        }
        return;
      } else if ("message" in error) {
        this.$toast.error(error["message"], {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });

        return;
      } else {
        this.$toast.error(error.message, {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      }
    },
  },
  computed: {
    computedAssetBtnDisabled() {
      if (this.selected_base_creative) {
        if (!this.asset_file) {
          return true;
        }
        return false;
      }
      return false;
    },
  },
  mounted() {
    this.fetchNotTestedCreatives();
  },
};
</script>

<style lang="scss" scoped></style>
