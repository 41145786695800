<template>
  <div class="bg-white p-6">
    <h1 class="font-semibold text-[18px] pb-5">Create a base video</h1>
    <div>
      <div>
        <v-select
          hide-details
          class="text-[13px]"
          item-text="title"
          return-object
          label="Choose a game"
          :loading="loading"
          :disabled="loading"
          outlined
          dense
          :items="game_list"
          v-model="selected_game"
        >
        </v-select>
      </div>
      <div class="mt-3" v-if="selected_game">
        <v-autocomplete
          hide-details
          class="text-[13px]"
          item-text="title"
          return-object
          label="Choose a creative"
          :loading="loading_assets"
          outlined
          dense
          :items="creative_list"
          v-model="selected_asset"
          hide-no-data
          :search-input.sync="search_title"
        >
        </v-autocomplete>
      </div>
    </div>
    <div class="mt-5 w-full" v-if="selected_asset">
      <div class="grid grid-cols-4 gap-4" v-if="parameters && selected_asset">
        <div
          class="hover:shadow-lg col-span-3 hover:border-gray-400 border border-gray-100 p-4 game-section"
        >
          <div class="w-full h-full" v-if="gameplayBeingCreated">
            <div
              class="flex items-center justify-center flex-col h-full w-full"
            >
              <div>
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </div>
              <span class="text-[12px] font-semibold text-gray-400 mt-2.5">
                Gameplay is being created... Please wait!
              </span>
            </div>
          </div>
          <div class="w-full h-full" v-else>
            <div
              class="flex items-center justify-center flex-col h-full w-full"
              v-if="!gameplaySignedURL"
            >
              <span class="text-[12px] font-semibold text-gray-400 mt-2.5">
                There is nothing to show. You can create a gameplay.
              </span>
            </div>
            <div v-else>
              <video
                :src="gameplaySignedURL"
                width="100%"
                height="100%"
                class="max-h-[630px]"
                controls
              ></video>
            </div>
          </div>
        </div>

        <div v-if="parameters" class="max-h-[630px]">
          <!-- Fashion Battle -->
          <div
            class="hover:shadow-lg border border-gray-100 hover:border-gray-400 component-container"
            v-if="selected_game.title.toLowerCase() === 'fashion battle'"
          >
            <DrillAndCollectGameParameters
              :parameters="parameters"
              @createGameplay="createGameplay"
              :loading="previewLoading"
              @levelChanged="handleOnLevelChange"
              :loadingPlayRecord="loadingPlayRecord"
              @save="handleSaveGameplay"
              :signedURL="gameplaySignedURL"
            />
          </div>

          <!-- Drill & Collect -->
          <div
            v-if="selected_game.title.toLowerCase() === 'drill and collect'"
            class="hover:shadow-lg border border-gray-100 hover:border-gray-400 component-container"
          >
            <DrillAndCollectGameParameters
              :parameters="parameters"
              @createGameplay="createGameplay"
              :loading="previewLoading"
              @levelChanged="handleOnLevelChange"
              :loadingPlayRecord="loadingPlayRecord"
              @save="handleSaveGameplay"
              :signedURL="gameplaySignedURL"
            />
          </div>
        </div>
      </div>
    </div>

    <SaveDialog
      :show="showSaveDialog"
      @closed="onCloseSaveDialog"
      :blob_info="gameplayBlobInfo"
      :used_parameters="parameters"
      :asset_id="this.selected_asset ? this.selected_asset.id : null"
      :build_id="this.build_info ? this.build_info.id : null"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
// import FashionBattleGameParameters from "@/components/tools/acg/Fashion_Battle/FashionBattleGameParameters.vue";
import DrillAndCollectGameParameters from "@/components/tools/acg/Drill_Collect/DrillAndCollectGameParameters.vue";
import { ACG_GAME_PIXELS, ACG_USER_INSTANCE_USERNAMES } from "@/helper/utils";
import SaveDialog from "@/components/tools/acg/SaveDialog.vue";

export default {
  data() {
    return {
      loading_assets: false,
      search_title: "",
      eventData: null,
      recordedVideo: null,
      parameters: null,
      tempParameters: null,
      gameParameterData: {},
      gameParametersData: [],
      iframe_src: null,
      loading: false,
      previewLoading: false,
      selected_game: null,
      build_info: null,
      game_list: [],
      game_url: null,
      show_snackbar: false,
      base_game_parameters: null,
      creative_list: [],
      loading_creative_list: false,
      selected_asset: null,
      loading_selected_asset: false,
      levelData: null,
      canCreateGameplay: false,
      server_id: null,
      gameplaySignedURL: null,
      gameplayBlobInfo: null,
      loadingPlayRecord: false,
      inputEvents: [],
      selectedInputEvent: null,
      inputEventFile: null,
      inputEventUploadLoading: false,
      inputEventsLoading: false,
      showSaveDialog: false,
      instance_url: null,
      gameplayBeingCreated: false,
    };
  },
  methods: {
    ...mapActions(["setAcgInstanceStatus"]),
    handleOnLevelChange(data) {
      this.levelData = data;
    },
    async fetchAssets(title) {
      if (this.selected_game) {
        try {
          this.loading_assets = true;
          let url = `act/assets/all/for-gameplay?selected_game_id=${this.selected_game.id}&title=${title}`;
          const response = await this.$api.get(url);
          this.creative_list = response.data;
        } catch (e) {
          console.log(e);
        } finally {
          this.loading_assets = false;
        }
      }
    },
    fetchGameList() {
      this.$api
        .get("acg/game-builds/all")
        .then((response) => {
          this.game_list = response.data.map((x) => x.game);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fetchBuildInfo(id) {
      this.$api
        .get("acg/game-builds/detail", {
          params: {
            game_id: id,
          },
        })
        .then((response) => {
          this.build_info = response.data;
        })
        .catch(() => {
          this.build_info = null;
        });
    },
    async playAndRecord() {
      try {
        this.gameplaySignedURL = null;
        this.gameplayBlobInfo = null;
        this.gameplayBeingCreated = true;
        const requestData = {
          build_id: this.build_info.id,
          asset_id: this.selected_asset ? this.selected_asset.id : null,
          parameters: JSON.stringify(this.parameters),
        };

        const playRecordResponse = await this.$api.post(
          "utils/instance/session/create-gameplay",
          requestData
        );

        if (playRecordResponse.status === 200) {
          this.gameplaySignedURL = playRecordResponse.data.signed;
          this.gameplayBlobInfo = playRecordResponse.data.blob;
        }
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.gameplayBeingCreated = false;
      }
    },
    async createGameplay() {
      // Check if instance is running
      try {
        if (!process.env.VUE_APP_ACG_MARKETING_URL) {
          this.$toast.error("Marketing Server URL is not configured.", {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });
          return;
        }

        this.loadingPlayRecord = true;

        // Check if user already logged in
        const sessionsResponse = await this.$api.get(
          `${process.env.VUE_APP_ACG_MARKETING_URL}/session-api/sessions`
        );

        const sessions = sessionsResponse.data.data;
        const filtered = sessions.filter(
          (x) => x.STATE.toLowerCase() === "active"
        );

        if (filtered.length) {
          // User already logged in.
          this.$toast("Your gameplay is being created. Please wait!", {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });
          await this.playAndRecord();
          return;
        } else {
          this.$toast.error(
            "There is no active session in Gameplay server. Contact with developer!",
            {
              timeout: 5000,
              position: "bottom-center",
              icon: false,
            }
          );
          return;
        }
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.loadingPlayRecord = false;
      }
    },
    errorHandler(error) {
      console.log(error);
      if ("response" in error) {
        if ("data" in error["response"]) {
          const isArr = this.$isArr(error["response"]["data"]["detail"]);
          var errorMessage = error["response"]["data"]["detail"];
          if (isArr) {
            errorMessage = JSON.stringify(errorMessage);
          }

          this.$toast.error(errorMessage, {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });
        }
        return;
      } else if ("message" in error) {
        this.$toast.error(error["message"], {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });

        return;
      } else {
        this.$toast.error(error.message, {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      }
    },
    handleSaveGameplay() {
      this.showSaveDialog = true;
    },
    onCloseSaveDialog() {
      this.showSaveDialog = false;
    },
  },
  computed: {
    ...mapGetters(["getAcgInstanceStatus", "getUserProfile"]),
    computedURL() {
      return URL.createObjectURL(this.recordedVideo);
    },
    isPlaying() {
      const video = document.getElementById("recorded-video");
      if (video) {
        return !video.paused;
      }
      return false;
    },
    getGamePixels() {
      return ACG_GAME_PIXELS;
    },
    getUserUsernames() {
      return ACG_USER_INSTANCE_USERNAMES;
    },
  },
  mounted() {
    this.fetchGameList();
  },
  watch: {
    search_title: _.debounce(function (newValue) {
      this.fetchAssets(newValue ? newValue : "");
    }, 500),
    async selected_asset(newValue) {
      try {
        const req = await this.$api.get(newValue.parameters_signed_url);
        const response = req.data;
        // Request'ten dönen cevaptaki default değerleri, parametrelerin default değerleri olarak ata.
        this.parameters = JSON.parse(JSON.stringify(response));
        this.gameplaySignedURL = null;
      } catch (error) {
        console.log(error);
      }
    },
    eventData() {
      this.show_no_recording_game = true;
    },
    selected_game(newValue) {
      if (newValue) {
        this.selected_asset = null;
        this.parameters = null;
        this.gameplaySignedURL = null;
        this.fetchBuildInfo(newValue.id);
      }
    },
    gameParameterData: {
      handler(newValue) {
        if (Object.keys(newValue).length) {
          var data = window.localStorage.getItem("gameParametersData")
            ? JSON.parse(window.localStorage.getItem("gameParametersData"))
            : [];
          data = data.filter((x) => x.game_id !== this.selected_game.id);
          data.push({
            ...newValue,
            build_id: this.build_info.id,
            game_id: this.selected_game.id,
          });

          window.localStorage.removeItem("gameParametersData");
          window.localStorage.setItem(
            "gameParametersData",
            JSON.stringify(data)
          );
        }
      },
      deep: true,
    },
  },
  // beforeRouteLeave(to, from, next) {
  //   this.deleteSavedPreview();
  //   next();
  // },
  // created() {
  //   window.addEventListener("beforeunload", this.deleteSavedPreview);
  // },
  components: {
    // FashionBattleGameParameters,
    DrillAndCollectGameParameters,
    SaveDialog,
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-btn__content {
  font-size: 13px !important;
  text-transform: capitalize;
}

.component-container {
  height: 630px;
  max-height: 630px;
  overflow-y: scroll;
  position: relative;
  .apply-button {
    background-color: white;
    max-height: 120px;
    position: sticky;
    border-top: 1px solid rgba($color: #000, $alpha: 0.05);
    bottom: 0;
    left: 0;
    width: 100%;
    box-shadow: 0 5px 15px rgba($color: #000000, $alpha: 0.2);
  }
}

.game-section {
  position: relative;
  .recording-icon-container {
    position: absolute;
    left: 50%;
    top: -13px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    z-index: 1;
    transform: translate(-50%, 0);
  }
}
</style>
